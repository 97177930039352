let nad,
    NewsArticleDynamic = {
        init: function () {
            this.bindUIActions();
        },
        bindUIActions: function () {
            const ajaxTpl = "/article/ajax/loadmore";
            let pageNum = 1;

            // in case there are multiple widgets on the same page
            document.querySelectorAll('.news-article-category-widget').forEach(widget => {
                const cat = widget.dataset.cat;

                let classCat;
                if (cat) {
                    classCat = cat.replace(/ /g, '_');
                } else {
                    classCat = '0';
                }

                const listingWidget = document.querySelector(
                    `.news-article-category-widget-${classCat} .ajax-listing-widget`
                );

                if (listingWidget) {
                    listingWidget.addEventListener('click', async (e) => {
                        e.preventDefault();

                        // request a template that only allows ajax requests.
                        // pass the page the number of articles already loaded.
                        // return 6 at a time.
                        const remaining_ajaxable_entries_count =
                            NewsArticleDynamic.getTotalAjaxableEntries(classCat);

                        const url = `${ajaxTpl}?page=${pageNum}&category=${cat}&remaining=${remaining_ajaxable_entries_count}`;

                        try {
                            const response = await fetch(url);
                            const data = await response.text();

                            const loadTarget = document.querySelector(
                                `.news-article-category-widget-${classCat} .load-target`
                            );

                            if (loadTarget) {
                                loadTarget.insertAdjacentHTML('beforeend', data);
                            }

                            // Check remaining entries after loading
                            const remaining_after_load =
                                NewsArticleDynamic.getTotalAjaxableEntries(classCat) -
                                (6 * pageNum);

                            if (remaining_after_load <= 0) {
                                const listingToHide = document.querySelector(
                                    `.news-article-category-widget-${classCat} .ajax-listing-widget`
                                );
                                if (listingToHide) {
                                    listingToHide.style.display = 'none';
                                }
                            }

                            pageNum++;

                        } catch (error) {
                            console.error('Error loading more articles:', error);
                        }
                    });
                }
            });
        },
        getTotalAjaxableEntries: function (classCat) {
            // total_ajaxable_entries is set inline by craft in the widgets/news-article-dynamic-widget.html template
            const loadTarget = document.querySelector(
                `.news-article-category-widget-${classCat} .load-target`
            );
            return loadTarget ? parseInt(loadTarget.dataset.totalAjaxableEntriesCount) || 0 : 0;
        },
    };

NewsArticleDynamic.init();