
import '@/scss/main.scss';

import $ from 'jquery';

// Vendor Files
import 'lazysizes';
import 'moment';
import './vendor/foundation';

// Modules
import './modules/hero.js';
import './modules/imagetextcarousel.js';
import './modules/featurecardcarousel.js';
import './modules/loadmoreblogarticles.js';
import './modules/newsarticledynamic.js';
import './modules/newsarticle.js';
import './modules/datapushga.js';
import './modules/userlogin.js';
import './modules/redboxherowidget.js';
import './modules/faqs.js';
import './modules/oembed.js';

// Bootstrap import
import './vendor/bootstrap';

// General
import './lib/general';

// Global
// TODO: Remove this when Foundation is removed
$(function() {
    // Foundation
    $(document).foundation();
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}