let oembed = {
    init: function () {
        document.querySelectorAll('oembed').forEach(function (oembed) {
            // get url from oembed
            let url = oembed.getAttribute('url');
            let oembedUrl = '';
            // Check for youtube
            if (url.includes('youtube.com')) {
                oembedUrl = 'https://www.youtube.com/oembed?url=' + url;
            }

            // Check for vimeo
            if (url.includes('vimeo.com')) {
                oembedUrl = 'https://vimeo.com/api/oembed.json?url=' + url;
            }

            fetch(oembedUrl)
            .then(response => response.json())
            .then(data => {
                let wrapper = document.createElement('div');
                wrapper.classList.add('ratio');
                wrapper.classList.add('ratio-16x9');
                wrapper.innerHTML = data.html;
                oembed.appendChild(wrapper);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        });
    }
}

oembed.init();