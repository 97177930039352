let faq,
    Faq = {
        init: function () {
            this.bindUIActions();
        },
        bindUIActions: function () {
            document.querySelectorAll('.faqAnswerFull').forEach(answer => {
                answer.style.display = 'none';
            });

            document.querySelectorAll('.learn-more-expand').forEach(button => {
                button.addEventListener('click', (e) => {
                    e.preventDefault();

                    let hacksawAnswer = button.parentElement.querySelector('.faqAnswerHacksaw');
                    let fullAnswer = button.parentElement.querySelector('.faqAnswerFull');

                    hacksawAnswer.style.display = hacksawAnswer.style.display === 'none' ? 'block' : 'none';
                    fullAnswer.style.display = fullAnswer.style.display === 'none' ? 'block' : 'none';
                });
            });
        },
    };

Faq.init();