let dpga,
    DataPushGa = {
        init: function () {
            this.bindUIActions();
        },
        bindUIActions: function () {
            // push the ga-data-push value to google as a click event
            document.querySelectorAll('[data-ga-push]').forEach(element => {
                element.addEventListener('click', () => {
                    const val = element.dataset.gaPush;
                    ga("send", "event", "Cta", "Click", val);
                });
            });
        },
    };

DataPushGa.init();