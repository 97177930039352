import Swiper from 'swiper/bundle';
let fcc,
    FeatureCardCarousel = {
        init: function () {
            let featureCardContainer = document.querySelector(".swiper-container.swiper-feature-card");
            if (featureCardContainer) {
                const swiper = new Swiper(".swiper-container.swiper-feature-card", {
                    slidesPerView: 4,
                    spaceBetween: 20,
                    loop: true,

                    pagination: {
                        el: ".swiper-pagination.fcc-swiper-pagination",
                    },

                    navigation: {
                        nextEl: ".swiper-button-next.fcc-swiper-button-next",
                        prevEl: ".swiper-button-prev.fcc-swiper-button-prev",
                    },

                    lazy: {
                        loadPrevNext: true,
                    },

                    breakpoints: {
                        1240: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        980: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        640: {
                            slidesPerView: 1,
                            grabCursor: true,
                            centeredSlides: true,
                            coverflowEffect: {
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            },
                        },
                    },
                });
            }
        },
    };

FeatureCardCarousel.init();