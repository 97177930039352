document.addEventListener("DOMContentLoaded", function() {
    // Lazy load
    document.addEventListener('lazybeforeunveil', function (e) {
        var bg = e.target.getAttribute('data-bg');
        if (bg) {
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });

    // Logo Resize on scroll
    window.addEventListener('scroll', function() {
        let scroll = {
            x: window.pageXOffset,
            y: window.pageYOffset
        };

        let logo = document.querySelector(".logo");
        if (logo) {
            if (scroll.y >= 125) {
                logo.classList.remove("big-logo");
            } else {
                logo.classList.add("big-logo");
            }
        }
    });

    // Flip Card
    let flipCard = document.querySelectorAll(".flip-card");
    if (flipCard) {
        flipCard.forEach(card => {
            card.addEventListener('click', function () {
                card.classList.toggle('flip');
            });
        });
    }

    // Banner
    let bannerWidget = document.querySelector(".banner-widget");
    if (bannerWidget) {
        let bannerClasses = document.querySelectorAll('.banner-active');
        let bannerSession = sessionStorage.getItem("banner") ;

        if (bannerSession != "clicked") {
            bannerWidget.classList.remove("d-none")
        }
        if (bannerSession == "clicked") {
            bannerClasses.forEach(element => element.classList.remove("banner-active"));
        }
    }

    // Banner Closer
    let bannerCloser = document.querySelector(".close-banner");
    if (bannerCloser) {
        bannerCloser.addEventListener('click', function () {
            let bannerWidget = document.querySelector(".banner-widget");
            bannerWidget.classList.add("d-none");

            let bannerClasses = document.querySelectorAll('.banner-active');

            bannerClasses.forEach(element => element.classList.remove("banner-active"));

            sessionStorage.setItem("banner", "clicked");
        });
    }

    // Navbar Side Collapse
    let navbarSideCollapse = document.querySelector('#navbarSideCollapse');
    if (navbarSideCollapse) {
        navbarSideCollapse.addEventListener('click', function () {
            document.querySelector('.offcanvas-collapse').classList.toggle('open');
            this.classList.toggle('open');
        });
    }

    // Search Button
    let searchButton = document.querySelector('#searchButton1');
    if (searchButton) {
        searchButton.addEventListener('click', function () {
            window.location.href = '/search-page';
            // let searchInput = document.querySelector('#searchInputAlgolia');
            // if (searchInput) {
            //     searchInput.focus();
            // }
        });
    }
    let searchButton2 = document.querySelector('#searchButton2');
    if (searchButton2) {
        searchButton2.addEventListener('click', function () {
            window.location.href = '/search-page';
            // let searchInput = document.querySelector('#searchInputAlgolia');
            // if (searchInput) {
            //     searchInput.focus();
            // }
        });
    }

    // Big Text
    let bigtext = document.querySelector(".bigtext");
    if (bigtext) {
        let bigtextContent = bigtext.querySelector(".bigtext-content");
        if (bigtextContent) {
            let textCount = bigtextContent.innerText.length;
            if (textCount < 12) {
                bigtextContent.style.fontSize = "14vw";
            } else {
                let textRatio = textCount * 0.3;
                let textSize = 14 - textRatio;
                if (textSize < 5 ) {
                    textSize = 5;
                }
                bigtextContent.style.fontSize = textSize + "vw";
            }
        }
    }

    // Clear Button
    document.querySelectorAll('.btn-clear').forEach(button => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
                checkbox.checked = false;
            });
        });
    });

    // Input switch
    document.querySelectorAll('.switch-input').forEach(switchInput => {
        switchInput.addEventListener('click', (e) => {
            if (switchInput.id !== 'usaon' && switchInput.id !== 'usaoff') {
                const withoutMap = document.querySelector('#without-map');
                const withMap = document.querySelector('#with-map');

                if (switchInput.id === 'mapoff') {
                    if (withoutMap) withoutMap.style.display = 'block';
                    if (withMap) withMap.style.display = 'none';
                } else {
                    if (withoutMap) withoutMap.style.display = 'none';
                    if (withMap) withMap.style.display = 'block';
                }
            }
        });
    });

    // Tabs section
    const tabsSection = document.querySelector('#tabs-section');
    if (tabsSection) {
        tabsSection.addEventListener('change.zf.tabs', (event) => {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, 10);
        });
    }

    // Post Category
    document.querySelectorAll('.postCategory').forEach(select => {
        select.addEventListener('change', () => {
            window.location.href = select.value;
        });
    });

    // Dropdown Button
    const dropdownButton = document.querySelector('#dropdown-button');
    if (dropdownButton) {
        dropdownButton.addEventListener('click', () => {
            const dropdownSelect = document.querySelector('#dropdown-select');
            if (dropdownSelect) {
                window.location = dropdownSelect.value;
            }
        });
    }

    // Search Field
    document.querySelectorAll('.search-field').forEach(searchField => {
        searchField.addEventListener('keyup', (e) => {
            document.querySelectorAll('.search-reset').forEach(resetButton => {
                resetButton.style.display = 'block';
            });
        });
    });

    // Search reset
    document.querySelectorAll('.search-reset').forEach(resetButton => {
        resetButton.addEventListener('click', (e) => {
            document.querySelectorAll('.search-reset').forEach(button => {
                button.style.display = 'none';
            });
        });
    });

    // Image CTA grid
    document.querySelectorAll('.image-cta-grid-widget').forEach(widget => {
        const loadMore = widget.querySelector('.load-more');
        if (loadMore) {
            loadMore.addEventListener('click', (e) => {
                e.preventDefault();

                for (let i = 0; i <= 5; i++) {
                    const cell = widget.querySelector(`.load-more-cell:nth-child(${i + 1})`);
                    if (cell) {
                        cell.style.display = 'block';
                    } else {
                        loadMore.style.display = 'none';
                        break;
                    }
                }
            });
        }
    });
});