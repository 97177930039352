let vm,
    ViewMore = {
        init: function () {
            this.bindUIActions();
        },
        bindUIActions: function () {
            let pageNum = 1;

            document.addEventListener('DOMContentLoaded', () => {
                let page = 2;
                const currentUrl = window.location.href;
                const eid = document.querySelector('[data-eid]')?.dataset.eid;
                const loadMoreButton = document.querySelector('#loadMore');
                const loadingElement = document.querySelector('#loading');
                const featuredBlogPosts = document.querySelector('#featured-blog-posts');

                if (loadMoreButton) {
                    loadMoreButton.addEventListener('click', (e) => {
                        e.preventDefault();
                        if (loadingElement) loadingElement.style.display = 'block';

                        const remaining_blog_entries_count = ViewMore.getTotalBlogEntries(eid);
                        const newUrl = currentUrl + '/p' + page;

                        // Replace jQuery $.get with fetch
                        fetch(newUrl, {
                            method: 'POST', // or 'GET', 'PUT', 'DELETE', etc.
                            headers: {
                            'Content-Type': 'application/json', // Adjust as needed
                            'X-Requested-With': 'XMLHttpRequest'
                            }
                        })
                        .then(response => response.text())
                        .then(data => {
                            if (featuredBlogPosts) {
                                featuredBlogPosts.insertAdjacentHTML('beforeend', data);
                            }
                            if (loadingElement) loadingElement.style.display = 'none';
                            page++;

                            // Handle the "done" logic
                            const remaining_blog_entries_count =
                                ViewMore.getTotalBlogEntries(eid) - 6 * pageNum;

                            if (remaining_blog_entries_count <= 6) {
                                loadMoreButton.style.display = 'none';
                            }
                            pageNum++;
                            console.log("loadmore")
                        })
                        .catch(error => {
                            console.error('Error loading more posts:', error);
                            if (loadingElement) loadingElement.style.display = 'none';
                        });
                    });
                }
            });
        },
        getTotalBlogEntries: function (eid) {
            // total_blog_entries is set inline by craft in the blog/_entry.html template
            let total_blog_entries_count = document.querySelector('.total-entries').dataset.totalBlogEntriesCount;
            return total_blog_entries_count;
        },
    };

    ViewMore.init();
