let na,
    NewsArticle = {
        init: function () {
            this.bindUIActions();
        },
        bindUIActions: function () {
            const ajaxTpl = "/article/ajax/loadmore-listing";
            let pageNum = 1;

            // in case there are multiple widgets on the same page
            document.querySelectorAll('.news-article-widget').forEach(widget => {
                const eid = widget.dataset.eid;
                const listingWidget = document.querySelector(
                    `.news-article-widget-${eid} .ajax-listing-widget`
                );

                if (listingWidget) {
                    listingWidget.addEventListener('click', async (e) => {
                        e.preventDefault();

                        // request a template that only allows ajax requests.
                        // pass the page the number of articles already loaded.
                        // return 6 at a time.
                        const remaining_ajaxable_entries_count =
                            NewsArticle.getTotalAjaxableEntries(eid);

                        const url = `${ajaxTpl}?page=${pageNum}&remaining=${remaining_ajaxable_entries_count}`;

                        try {
                            const response = await fetch(url, {
                                method: 'POST', // or 'GET', 'PUT', 'DELETE', etc.
                                headers: {
                                'Content-Type': 'application/json', // Adjust as needed
                                'X-Requested-With': 'XMLHttpRequest'
                                }
                            });
                            const data = await response.text();

                            const loadTarget = document.querySelector(
                                `.news-article-widget-${eid} .load-target`
                            );

                            if (loadTarget) {
                                loadTarget.insertAdjacentHTML('beforeend', data);
                            }

                            // Check remaining entries after loading
                            const remaining_after_load =
                                NewsArticle.getTotalAjaxableEntries(eid) -
                                (3 * pageNum);

                            if (remaining_after_load <= 0) {
                                const listingToHide = document.querySelector(
                                    `.news-article-widget-${eid} .ajax-listing-widget`
                                );
                                if (listingToHide) {
                                    listingToHide.style.display = 'none';
                                }
                            }

                            pageNum++;

                        } catch (error) {
                            console.error('Error loading more articles:', error);
                        }
                    });
                }
            });
        },
        getTotalAjaxableEntries: function (eid) {
            // total_ajaxable_entries is set inline by craft in the widgets/news-article-dynamic-widget.html template
            const loadTarget = document.querySelector(
                `.news-article-widget-${eid} .load-target`
            );
            return loadTarget ? parseInt(loadTarget.dataset.totalAjaxableEntriesCount) || 0 : 0;
        },
    };

NewsArticle.init();