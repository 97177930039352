let rb,
    RedBox = {
        init: function () {
            this.bindUIActions();
        },
        bindUIActions: function () {
            const redirect_url = RedBox.getParameterByName("Return_URL");
            if (redirect_url) {
                const link = document.querySelector('#loginCTA');
                if (link) {
                    const currentHref = link.getAttribute('href');
                    link.setAttribute('href', `${currentHref}?Return_URL=${redirect_url}`);
                }
            }
        },
        getParameterByName: function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
    };

RedBox.init();