let ul,
    UserLogin = {
        settings: {
            username: "",
            password: "",
            tokenData: "",
            progressID: "",
            waivers: [],
        },
        init: function () {
            ul = this.settings;
            this.bindUIActions();
        },
        bindUIActions: function () {
            let red_url = UserLogin.getParameterByName("Return_URL") ?? "";
            let pagename_param = UserLogin.getParameterByName("pagename") ?? "";
            let loginForm = document.getElementById("login-form") ?? "";
            let loginAlert = document.getElementById("login-alert") ?? "";
            if (loginAlert) {
                if (red_url) {
                    loginAlert.style.display = "none";
                } else {
                    loginAlert.style.display = "none";
                }
            }
            if (loginForm) {
                axios
                    .get("/API/profile/")
                    .then(function (response) {
                        if (response.data.first_name) {
                            if (red_url) {
                                location.href = red_url;
                            } else {
                                if (pagename_param) {
                                    location.href = "https://legacy.usacycling.org/myusac/index.php?pagename=" + pagename_param;
                                } else {
                                    location.href = "https://legacy.usacycling.org/myusac/index.php";
                                }
                            }
                        }
                    })
                    .catch(function (error) {});

                document.querySelectorAll('input').forEach(input => {
                    input.addEventListener('keypress', (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                            e.preventDefault();
                            UserLogin.loginUser();
                            return false;
                        }
                    });
                });
            }
            document.addEventListener("invalid.zf.abide", function (e) {
                document.querySelector(".login-progress").style.display = "none";
            });

            document.querySelectorAll('.btn-login-action').forEach(button => {
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    UserLogin.loginUser();
                });
            });

            document.querySelectorAll('.btn-register-action').forEach(button => {
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    var register_url = "https://myaccount.usacycling.org/signup";

                    location.href = register_url;
                });
            });
        },
        waiversPresent: function () {
            axios
                .get("/API/checkwv/?xfg=293381")
                .then(function (response) {
                    if (
                        response.data[0].id == "" ||
                        response.data[0].id == undefined ||
                        response.data.detail == "Invalid username/password."
                    ) {
                        return false;
                    } else {
                        location.href = "/waivers";
                        return true;
                    }
                })
                .catch(function (error) {
                    return false;
                });
        },
        loginUser: function () {
            var red_url = UserLogin.getParameterByName("Return_URL");
            var pagename_param = UserLogin.getParameterByName("pagename");

            // TODO: FOUNDATION REMOVAL
            $(".log-in-form").foundation("validateForm");

            UserLogin.username = document.getElementById("username").value;
            UserLogin.password = document.getElementById("password").value;

            document.querySelector(".error-msg").style.display = "none";
            document.querySelector(".error-msg").innerHTML = "";

            document.querySelector(".btn-search-text").classList.toggle("active");
            document.querySelector(".loader").classList.toggle("active");

            let formData = new FormData();
            formData.append('username', UserLogin.username);
            formData.append('password', UserLogin.password);

            axios
                .post('/API/login/', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                })
                .then(function (response) {
                    if (response.data.loggedIn) {
                        if (red_url) {
                            location.href = red_url;
                        } else {
                            if (pagename_param) {
                                location.href = "https://legacy.usacycling.org/myusac/index.php?pagename=" + pagename_param;
                            } else {
                                location.href = "https://legacy.usacycling.org/myusac/index.php";
                            }
                        }
                    } else {
                        const btnSearchText = document.querySelector('.btn-search-text');
                        const loader = document.querySelector('.loader');
                        const errorMsg = document.querySelector('.error-msg');

                        btnSearchText.classList.toggle('active');
                        loader.classList.toggle('active');
                        errorMsg.innerHTML = 'Invalid username and/or password.';
                        errorMsg.style.display = 'block';
                    }
                })
                .catch(function (error) {
                    console.log("Error:" + error);
                    const btnSearchText = document.querySelector('.btn-search-text');
                    const loader = document.querySelector('.loader');
                    const errorMsg = document.querySelector('.error-msg');

                    loader.classList.toggle('active');
                    btnSearchText.classList.toggle('active');
                    errorMsg.innerHTML = 'Invalid username and/or password.';
                    errorMsg.style.display = 'block';
                });
        },
        getParameterByName: function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }
    };

UserLogin.init();