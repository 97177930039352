import Swiper from 'swiper/bundle';
let itc,
    ImageTextCarousel = {
        init: function () {
            let swiperContainer = document.querySelector('.swiper-container.swiper-image-text-carousel');
            if (swiperContainer) {
                const swiper = new Swiper('.swiper-container.swiper-image-text-carousel', {
                    effect: 'fade',
                    loop: true,
                    pagination: {
                        el: '.swiper-image-text-carousel .swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-image-text-carousel .swiper-button-next',
                        prevEl: '.swiper-image-text-carousel .swiper-button-prev',
                    },
                    fadeEffect: {
                        crossFade: true,
                    },
                    spaceBetween: 30,
                    lazy: {
                        loadPrevNext: true,
                    },
                    autoplay: {
                        delay: 6000,
                        disableOnInteraction: false
                    },
                });
            }
        },
    };

ImageTextCarousel.init();